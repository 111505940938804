<template>
  <div id="mbti-result" class="section">
    <div class="jumbotron text-right">
      <div class="container">
        <h2>Tipe kepribadianmu adalah</h2>
        <h1>INFP</h1>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-lg-7">
          <p>
            Kepribadian manusia sangatlah beragam salah satunya adalah INFP sering disebut the idealist atau seseorang yang idealis.  Hanya ada 4-5% populasi INFP di dunia. Dimana menurut dimensinya INFP berarti Introversion atau seseorang yang tidak suka akan hubungan dan dunia luar. Intuition dimana mereka membicarakan hal yang bersifat masa depan dan belum jelas atau abstrak. Feeling yaitu mereka memutuskan berbagai hal dengan perasaan dan menyangkut pribadi serta Perception  yakni pemikiran yang terbuka dan cenderung menunda keputusan demi kesepakatan
          </p>
          <p>
            Ciri kepribadian INFP adalah:
            <ul>
              <li> Loyal dan biasanya mengabdikan diri untuk orang lain.</li>
              <li> Fleksibel, santai, kreatif dan memegang prinsip.</li>
              <li> Sangat sensitive dan juga kompleks, dimana mereka tidak suka bekerja dengan rutunitas dan mementingkan hasil akhir.</li>
              <li> Selalu ingin berkembang dan tidak ingin diam di tempat.</li>
            </ul>
          </p>
          <p>Saran dan pekerjaan yang cocok:
            <ul>
              <li>
                Jangan terlalu menyalahkan diri sendiri ketika mengalami hal yang membuat anda hancur dan target yang tidak tercapai.
              </li>
              <li>
                Jangan terlalu baik ataupun terlalu keras pada orang lain semua memiliki porsinya masing-masing.
              </li>
              <li>
                Belajar untuk bersikap tegas dan juga terbuka, jangan lakukan apapun secara diam-diam dan menyakiti orang lain.
              </li>
              <li>
                Pekerjaan yang cocok adalah guru, penasihat, pekerja keagamaan, penulis dan professor.
              </li>
            </ul>
          </p>
          <p>
            Asmara untuk INFP adalah mereka yang memiliki kepribadian ENFJ atau ESFJ yang mungkin lebih bisa mengimbangi pribadi INFP.
          </p>
        </div>
        <div class="col-lg-5">
          <img src="../../../assets/images/mbti/INFP.png" alt="" class="illustration">
          <div class="media">
            <img src="../../../assets/images/mbti/Introversion.png" class="mr-3" alt="">
            <div class="media-body">
              <h5 class="introversion">Introversion</h5>
              <p>cenderung pendiam dan suka menyendiri</p>
            </div>
          </div>
          <div class="media">
            <img src="../../../assets/images/mbti/Intution.png" class="mr-3" alt="">
            <div class="media-body">
              <h5 class="intution">Intution</h5>
              <p>lebih fokus terhadap gambaran masa depan</p>
            </div>
          </div>
          <div class="media">
            <img src="../../../assets/images/mbti/Feeling.png" class="mr-3" alt="">
            <div class="media-body">
              <h5 class="feeling">Feeling</h5>
              <p>membuat keputusan berdasarkan logika</p>
            </div>
          </div>
          <div class="media">
            <img src="../../../assets/images/mbti/Perceiving.png" class="mr-3" alt="">
            <div class="media-body">
              <h5 class="perceiving">Perceiving</h5>
              <p>cenderung membiarkan pilihan tetap terbuka</p>
            </div>
          </div>
        </div>
      </div>
      <ShareButton :result="result" />
    </div>
  </div>
</template>

<script>
export default {
  name: "INFP",
  components: {
    ShareButton: () => import('@/components/ShareButton'),
  },
  data() {
    return {
      result: {
        name: 'INFP',
        slug: 'infp'
      }
    }
  }
  
};
</script>

<style scoped>

</style>
